export const PRIVATE_ROUTE = {
    AUTH_NOT_FOUND: '*',
    AUTH_LOGIN: '/',
    AUTH_REGISTER: '/register',
    AUTH_CONFIRM_USERNAME: (userId) => `/confirm/username/${userId}`
}

export const PUBLIC_ROUTE = {
    HOME_PAGE:`/game`,
    GAME_CATEGORY: '/game/category',
    PREMIER_LEAGUE: '/game/premier/league',
    CHAMPIONS_LEAGUE: '/game/champions/league',
    WORLD_CUP: '/game/world/cup',
    LIVE_QUESTION_PAGE: (gameweekId, league) => `/game/live/questions/${league}/${gameweekId}`,
    DEMO_QUESTION_PAGE: '/game/demo/questions',
    GAMEWEEK_PAGE: (league) =>  `/game/gameweek/${league}/list`,
    WALLET_PAGE: '/wallet',
    CREDIT_PAGE: '/wallet/credit',
    REWARD_PAGE: '/wallet/reward',
    LEADER_BOARD: `/leader/board`,
    SETTING_PAGE: `/settings`
}