/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import InternalLayout from '../../layout/InternalLayouts';

const ComposeInternalLayouts = (Component) => (passThroughProps) => (
    <>
        <InternalLayout {...passThroughProps} Component={Component} />
    </>
);

ComposeInternalLayouts.propTypes={
  passThroughProps: PropTypes.node
}


export default ComposeInternalLayouts;