import { CONSTANT_TEXT } from "../helpers/constant";


export const getAuthUser = () => {
	const user = localStorage.getItem(CONSTANT_TEXT.APP_AUTH_TOKEN);
	return JSON.parse(user);
};
export const setAuthUser = (user) => {
	localStorage.setItem(CONSTANT_TEXT.APP_AUTH_TOKEN, JSON.stringify(user));
};
export const removeAuthUser = () => {
	localStorage.removeItem(CONSTANT_TEXT.APP_AUTH_TOKEN);
};

export const getUserId = () => {
	const userId = localStorage.getItem(CONSTANT_TEXT.APP_USER_ID);
	return JSON.parse(userId)
};

export const setUserId = (userId) => {
	localStorage.setItem(CONSTANT_TEXT.APP_USER_ID, JSON.stringify(userId))
}