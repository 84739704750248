import React, { createContext } from "react";
import { apiUrl } from "../api/apiUrl";
import PropTypes from 'prop-types';
import authApi from "../api/authApi";
import authToken from "../api/authToken";
import { PRIVATE_ROUTE } from "../routes/url";
import toast from "react-hot-toast";
import { removeAuthUser } from "../api";

const { REACT_APP_BACKEND_URL } = process.env;

export const authContext = createContext({
    logout: () => { },
    login: () => { },
    register: () => { },
    username: () => { },
});

export const AuthProvider = ({ children }) => {
    
    const login = (payload, responses, err) => {
        const loginUrl = `${REACT_APP_BACKEND_URL}/${apiUrl.LOGIN_URL}`;
        return authToken(loginUrl, payload, apiUrl.POST, responses, err)
    };

    const register = (payload, responses, err) => {
        const registerUrl = `${REACT_APP_BACKEND_URL}/${apiUrl.REGISTER_URL}`;
        return authApi(registerUrl, payload, apiUrl.POST, responses, err)
    };
    const username = (payload, userId, responses, err) => {
        const usernameUrl = `${REACT_APP_BACKEND_URL}/${apiUrl.USERNAME_URL(userId)}`;
        return authApi(usernameUrl, payload, apiUrl.PATCH, responses, err)
    };

    // const resetPassword = (payload, responses) => {
    //     const resetPasswordUrl = `${REACT_APP_BACKEND_URL}/${apiUrl.RESET_PASSWORD_URL}`;
    //     return authApi(resetPasswordUrl, payload, apiUrl.POST, responses)
    // }

    const logout = (navigate) => {
        removeAuthUser()
        navigate(PRIVATE_ROUTE.AUTH_LOGIN)
        toast.success("Logged out successfully!")
    };

    const values = {
        logout,
        login,
        register,
        username,
        // resetPassword
    }

    return <authContext.Provider value={values}>{children}</authContext.Provider>
};

AuthProvider.propTypes = {
    children: PropTypes.node
}