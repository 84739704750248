export const CONSTANT_TEXT = {
    APP_AUTH_TOKEN: 'soccer_wiz_token',
    APP_USER_ID: 'user_id',
    USER_DASHBOARD: 'user',
    USER_CATEGORY: 'category',
    LEADER_BOARD: 'leaderBoard',
    LIVE_QUESTION: 'live-questions',
    DEMO_QUESTION: 'demo-questions',
    USER_WALLET: 'wallet',
    BUY_CREDIT: 'buy_credit',
    REWARD_USER: 'reward_user',
    PAYMENT_LINK: 'payment_link',
    GAME_WEEK: 'game_week'
}