import React from "react";
import { PRIVATE_ROUTE } from "./url";
const Login = React.lazy(() => import("../pages/private/Login"));
const Register = React.lazy(() => import("../pages/private/Register"));
const ConfirmUsername = React.lazy(() => import("../pages/private/ConfirmUsername"));

export const privateRoute = [
    {
        path: PRIVATE_ROUTE.AUTH_NOT_FOUND,
        element: <Login />,
        exact: true,
    },
    {
        path: PRIVATE_ROUTE.AUTH_LOGIN,
        element: <Login />,
        exact: true,
    },
    {
        path: PRIVATE_ROUTE.AUTH_REGISTER,
        element: <Register />,
        exact: true,
    },
    {
        path: PRIVATE_ROUTE.AUTH_CONFIRM_USERNAME(":userId"),
        element: <ConfirmUsername />,
        exact: true,
    },
]