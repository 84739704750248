import { getUserId } from "."

const getId = getUserId();

export const apiUrl = {
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    REGISTER_URL: 'auth/register',
    LOGIN_URL: 'auth/login',
    USER_URL: `api/users/me`,
    USERNAME_URL: (userId) => `api/users/${userId}/username`,
    DASHBOARD_URL: 'api/users/me',
    CATEGORY_URL: 'api/settings/game',
    GOOGLE_AUTH_URL: 'auth/google',
    QUESTION_URL: `api/questions/game/demo/user/${getId}`,
    LIVE_QUESTION_URL: (gameweekId, league) => `api/questions/game/user/${getId}/gameweek/${gameweekId}?category=${league}`,
    SUBMIT_QUESTION_URL: 'api/games/submit',
    WALLET_URL: `api/wallet/transactions/user/${getId}`,
    BUY_CREDIT: `api/settings/buyList`,
    USER_REWARD: `api/rewards/user/${getId}`,
    GAMEWEEK_URL: (leagueName) => `api/gameWeeks/category/${leagueName}/list`,
    LEADER_BOARD: (league, activeTag) => `api/scores/leaderboard/user/${getId}/category/${league}?period=${activeTag}`,
    PAYMENT_LINK: (amount) => `api/payments/flw/pay-link/user/${getId}?currency=NGN&amount=${amount}`,
    WITHDRAW_URL: `api/users/${getId}/withdraw-settings`,
}