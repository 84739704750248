import { getAuthUser } from "./index";

const authToken = async (url, payload, method, responses, err) => {

    const token = getAuthUser();

    const headers = new Headers({
        "Content-Type": "application/json",
        Accept: "/",
        "Access-Control-Allow-Origin": "*",
        "x-auth-token": `${token}`,
    });

    const request = new Request(url, {
        method: method,
        headers,
        body: JSON.stringify(payload)
    });
    
    await fetch(request)
        .then((responses) => responses.json())
        .then(responses)
        .catch(err)
};

export default authToken;