import React from "react";
import { PUBLIC_ROUTE } from "./url";
const HomePage = React.lazy(() => import("../pages/public/Home"));
const LeaderBoard = React.lazy(() => import("../pages/public/LeaderBoard"));
const GameCategory = React.lazy(() => import("../pages/public/Home/GameCategory"));
const PremierLeague = React.lazy(() => import("../pages/public/Home/PremierLeague"));
const ChampionLeague = React.lazy(() => import("../pages/public/Home/ChampionLeague"));
const WorldCup = React.lazy(() => import("../pages/public/Home/WorldCup"));
const LiveQuestion = React.lazy(() => import("../pages/public/Home/LiveQuestion"));
const Wallet = React.lazy(() => import("../pages/public/Wallet"));
const Credit = React.lazy(() => import("../pages/public/Wallet/Credit"));
const Reward = React.lazy(() => import("../pages/public/Wallet/Reward"))
const Settings = React.lazy(() => import("../pages/public/Settings"))
const DemoQuestion = React.lazy(() => import("../pages/public/Home/DemoQuestion "));
const Gameweek = React.lazy(() => import("../pages/public/Home/Gameweek"));
import ComposeInternalLayouts from "../components/HOC/ComposeInternalLayout";


export const publicRoutes = [
    {
        path: PUBLIC_ROUTE.HOME_PAGE,
        element: ComposeInternalLayouts(HomePage)
    },
    {
        path: PUBLIC_ROUTE.LEADER_BOARD,
        element: ComposeInternalLayouts(LeaderBoard)
    },
    {
        path: PUBLIC_ROUTE.GAME_CATEGORY,
        element: ComposeInternalLayouts(GameCategory)
    },
    {
        path: PUBLIC_ROUTE.CHAMPIONS_LEAGUE,
        element: ComposeInternalLayouts(ChampionLeague)
    },
    {
        path: PUBLIC_ROUTE.WORLD_CUP,
        element: ComposeInternalLayouts(WorldCup)
    },
    {
        path: PUBLIC_ROUTE.PREMIER_LEAGUE,
        element: ComposeInternalLayouts(PremierLeague)
    },
    {
        path: PUBLIC_ROUTE.LIVE_QUESTION_PAGE(":gameweekId",":league"),
        element: ComposeInternalLayouts(LiveQuestion)
    },
    {
        path: PUBLIC_ROUTE.DEMO_QUESTION_PAGE,
        element: ComposeInternalLayouts(DemoQuestion)
    },
    {
        path: PUBLIC_ROUTE.GAMEWEEK_PAGE(":league"),
        element: ComposeInternalLayouts(Gameweek)
    },
    {
        path: PUBLIC_ROUTE.WALLET_PAGE,
        element: ComposeInternalLayouts(Wallet)
    },
    {
        path: PUBLIC_ROUTE.CREDIT_PAGE,
        element: ComposeInternalLayouts(Credit)
    },
    {
        path: PUBLIC_ROUTE.REWARD_PAGE,
        element: ComposeInternalLayouts(Reward)
    },
    {
        path: PUBLIC_ROUTE.SETTING_PAGE,
        element: ComposeInternalLayouts(Settings)
    },
]